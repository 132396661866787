<template>
  <v-theme-provider light>
    <v-form ref="form" v-model="formValid" @submit.prevent>
      <v-overlay :value="isPageLoading">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
      </v-overlay>
      <div>
        <BannerAccepted v-if="isAcceptedProp && !isPageLoading" :timeout="infinityTimeoutProp" :value="true" shaped color="primary" />
      </div>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="12">
          <v-snackbar v-model="showGenericFailure" :timeout="infinityTimeoutProp">
            <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
              <span class="centered">
                <span>That's unexpected! {{ genericFailureErrorMessage }}</span>
                <v-spacer />
                <v-btn
                  @click="
                    showGenericFailure = false
                    isPageLoading = false
                  "
                  text
                  style="display: flex; justify-content: flex-end"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </span>
            </v-container>
          </v-snackbar>
        </v-col>
      </v-row>
      <base-section space="86">
        <base-section-heading title="Invitation" color="accent"><v-icon left>mdi-web</v-icon>{{ networkname }}</base-section-heading>
        <v-responsive class="d-flex align-center mx-auto mb-4" height="100%" max-width="90%">
          <v-container fluid>
            <v-row>
              <v-col class="col">
                <!-- <v-card>
                  <v-toolbar class="bluegreylighten4" dense elevation="0">
                    <v-toolbar-title>Invitation web page</v-toolbar-title>
                  </v-toolbar>
                  <v-sheet color="bluegreylighten5 text-center">
                    <br />
                    <v-spacer /><v-spacer />
                    <v-btn :href="invitationUrl" class="v-size--x-large primary">Visit my page</v-btn>
                    <v-spacer /><br />
                  </v-sheet>
                </v-card>
                <br /> -->
                <v-card>
                  <v-toolbar class="bluegreylighten4" dense elevation="0">
                    <v-toolbar-title>Invitation QR code</v-toolbar-title>
                  </v-toolbar>
                  <v-sheet color="bluegreylighten5 text-center">
                    <v-list-item>
                      <v-list-item-content>
                        <v-img v-if="invitationUrl === ''" width="200" height="200" color="bluegreylighten5" />
                        <qrcode-vue v-if="invitationUrl !== ''" renderAs="svg" :value="invitationUrl" :size="200" level="L" />
                      </v-list-item-content>
                    </v-list-item>
                    <v-spacer />
                    <v-btn class="v-size--x-large primary" @click="qrCodeDialogVisible = true">Full screen code</v-btn>
                    <v-spacer /><br />
                  </v-sheet>
                </v-card>
                <br />
                <v-card>
                  <v-toolbar class="bluegreylighten4" dense elevation="0">
                    <v-toolbar-title>Invitation email</v-toolbar-title>
                  </v-toolbar>
                  <v-sheet color="bluegreylighten5 text-center">
                    <v-list-item>
                      <v-list-item-content>
                        <div class="font-weight-bold text-h5">Wealth Advisory Team</div>
                        <div class="font-weight-bold">“{{ networkname }}”</div>

                        <div><br /><br />I'm creating a Wealth Advisory Team with other professionals, and I'd like you to consider being a part of it.</div>
                        <div>This team will be exclusive, meaning only one professional per profession.</div>
                        <div><br />Additionally, our team will be connected to our national “{{ networkname }}” referral network of professionals who are PI-Certified™.</div>
                        <span><br />Follow this invitation link to learn more about PI Certification and join our Wealth Advisory Team :</span>
                        <span color="primary">
                          <a>{{ invitationUrl }}</a>
                        </span>
                        <span><br />Keep your invitation code handy:</span>
                        <div>{{ redeemId }}</div>
                      </v-list-item-content>
                    </v-list-item>
                    <br />
                    <v-list-item>
                      <v-col>
                        <v-avatar tile size="144">
                          <v-img class="profile-image-style" :src="fromavatar"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col>Sincerely,<br />{{ fromname }}.</v-col>
                    </v-list-item>
                    <v-spacer />
                    <v-btn class="v-size--x-large primary" @click="composeInvitationAction()">Compose an Email</v-btn>
                    <v-spacer /><br />
                  </v-sheet>
                </v-card>
                <!-- <v-card>
                  <v-toolbar class="accent" dark dense elevation="0">
                    <v-toolbar-title>Send invitation to:</v-toolbar-title>
                  </v-toolbar>
                  <v-sheet color="bluegreylighten5">
                    <v-card-title>
                      <v-row class="align-center">
                        <v-col cols="12" md="6" class="mb-0">
                          <v-text-field outlined v-model="invitationmessage" label="Name" required :rules="rules.name" />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined v-model="toemail" label="Email address" required :rules="rules.email" class="text-right mr-24" />
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <div class="text-right mr-4">
                      <v-btn :disabled="!formValid" class="v-size--x-large accent" @click="composeInvitationAction()"> Send </v-btn>
                      <br /><br />
                    </div>
                  </v-sheet>
                </v-card> -->
              </v-col>
            </v-row>
            <v-dialog v-model="qrCodeDialogVisible" fullscreen transition="dialog-bottom-transition">
              <v-card align="center">
                <v-card-title class="title">Scan this code</v-card-title>
                <v-sheet color="white text-center" v-if="redeemId !== ''">
                  <qrcode-vue :value="invitationUrl" :size="this.$parent.$el.scrollWidth - this.codemargin" level="L"></qrcode-vue>
                </v-sheet>
                <v-card-actions>
                  <br /><v-spacer />
                  <v-btn color="primary" @click="qrCodeDialogVisible = false">Done</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-responsive>
      </base-section>
    </v-form>
  </v-theme-provider>
</template>

<script>
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { EnvX, envx } from '@/environments/EnvX.ts'
import { RegistrationStage, AlertTimeout } from '@/RegistrationStage.ts'
import QrcodeVue from 'qrcode.vue'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  components: {
    QrcodeVue,
    BannerAccepted: () => import('./BannerAccepted.vue')
  },

  name: 'InvitationSection',
  data: () => ({
    formValid: false,
    isPageLoading: true,
    registrationStage: RegistrationStage.Disabled,
    toemail: '',
    invitationmessage: '',
    fromname: '',
    fromavatar: '',
    networkname: '',
    fromid: '',
    showGenericFailure: false,
    genericFailureErrorMessage: '',
    invitationUrl: '',
    redeemId: '',
    codemargin: 40,
    rules: {
      email: [(v) => !!v || 'Email address is required'],
      name: [(v) => (v || '').length > 0 || 'Name is required']
    },
    qrCodeDialogVisible: false
  }),

  mounted() {
    setTimeout(this.fetchProfileThenCampaign, this.nullTimeoutProp)
  },

  computed: {
    nullTimeoutProp() {
      return AlertTimeout.null
    },

    infinityTimeoutProp() {
      return AlertTimeout.infinity
    },

    isAcceptedProp() {
      return this.registrationStage === RegistrationStage.Accepted
    }
  },

  methods: {
    fetchProfileThenCampaign() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🎉 InvitationSection.fetchProfileThenCampaign', // log hint
        endpoints.myprofileEndpoint, // endpoint
        environmentVariables.MY_PROFILE_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.genericFailureErrorMessage = ''
          this.isPageLoading = true
          this.showGenericFailure = false

          return true
        },

        // onSuccess
        (data) => {
          this.fetchCampaignForProfile(data.profile)
        },

        // All sort of errors
        (error, errorDescription) => {
          this.isPageLoading = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showGenericFailure = true
            this.genericFailureErrorMessage = errorDescription
          }
        }
      )
    },

    fetchCampaignForProfile(profile) {
      this.registrationStage = profile.registrationstage
      this.fromname = profile.fullname
      this.fromid = profile.id
      this.fromavatar = profile.avatar
      this.networkname = profile.networkname
      this.invitationmessage = `Join ${this.fromname} & the ${this.networkname} Network!`

      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🎀 InvitationSection.fetchCampaignForProfile', // log hint
        endpoints.myInvitationsEndpoint, // endpoint
        environmentVariables.MY_INVITATIONS_KEY, // code
        {
          personals: false,
          campaigns: true
        }, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.genericFailureErrorMessage = ''
          this.isPageLoading = true
          this.showGenericFailure = false

          return true
        },

        // onSuccess
        (data) => {
          this.formValid = true
          this.isPageLoading = false
          const campaign = data.list[0]
          this.redeemId = campaign.redeemid
          this.invitationUrl = `${envx.webserverurl}/invite?invitation=${this.redeemId}`
          // Line below to generate a OTP QR Code
          // this.invitationUrl = 'otpauth://totp/westus?secret=ABC123&issuer=ProInsight&algorithm=SHA1&digits=6&period=30'
          EnvX.log(`invite: ${this.invitationUrl}`)
        },

        // All sort of errors
        (error, errorDescription) => {
          this.formValid = true
          this.isPageLoading = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showGenericFailure = true
            this.genericFailureErrorMessage = errorDescription
          }
        }
      )
    },

    composeInvitationAction() {
      this.$refs.form.validate()

      try {
        this.formValid = false
        const body = `I'm creating a Wealth Advisory Team with other professionals, and I'd like you to consider being a part of it.\nThis team will be exclusive, meaning only one professional per profession. .\n\nAdditionally, our team will be connected to our national “${this.networkname}” referral network of professionals who are PI-Certified™ (Relational Intelligence)..\n\nFollow this invitation link to learn more about PI Certification and join our Wealth Advisory Team:\n\n${this.invitationUrl}\n\nKeep your invitation code handy:\n${this.redeemId}\n\nSincerely,\n${this.fromname}.`
        const subject = ' Join my Wealth Advisory Team'
        document.location.href = `mailto:${this.toemail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
        this.formValid = true
      } catch (exception) {
        EnvX.error('💥', exception)
        this.showGenericFailure = true
        this.genericFailureErrorMessage = exception
      }
    },

    showLoggedOutBanner() {
      // MustLoginSection should already have a modal
    }
  }
}
</script>

<style>
a {
  text-decoration: none;
  color: #fff;
}

.centered {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
</style>
